<template>
  <div>
    <div class="card pad">
      <div class="row">
        <div
          class="col-12 col-xl-3 d-flex justify-content-start justify-content-xl-center mb-3"
          v-if="profilePhoto"
        >
          <img class="profile-photo" :src="profilePhoto" />
        </div>
        <div class="col-12 col-xl-8">
          <h1 class="mb-4 mb-md-3">
            {{
              $t('STUDIO.SECTIONS.WELCOME_TO', {
                studio_name: studio.studio_name,
              })
            }}
          </h1>
          <HMCustomContent
            v-if="
              studioDescriptionExists(studio.about_text, studio.about_text_new)
            "
            :html="studio.about_text"
            :json="studio.about_text_new"
          />
        </div>
      </div>
    </div>
    <div class="card mt-4" v-if="hasWelcomeVideo">
      <HMFilePreview
        :file="studio.welcome_video"
        class="welcome-video-container rounded"
      />
    </div>
    <div class="card mt-4">
      <HMStudioHeader v-if="studio" :image="headerImage" />
    </div>
    <div class="card pad mt-4">
      <div class="row">
        <div class="col-12 col-lg-5">
          <HMStudioHeader
            class="text-center no-border-radius img-about tall"
            v-if="studio.about_photo"
            :image="studio.about_photo"
          />
        </div>
        <div class="col-sm-12 col-lg-7 mt-4 mt-lg-0">
          <h2>{{ $t('STUDIO.SECTIONS.ABOUT_US') }}</h2>
          <HMCustomContent
            class="mt-4"
            :html="studio.about_studio"
            :json="studio.about_studio_new"
          />
        </div>
      </div>
    </div>
    <div class="card mt-4">
      <div class="product-nav d-flex flex-row justify-content-between">
        <h2>{{ $t('STUDIO.SECTIONS.PRODUCTS') }}</h2>
        <div class="navigation d-flex flex-row flex-nowrap me-3 me-md-5">
          <div
            class="swiper-nav swiper-nav--prev me-2"
            :class="{ disabled: swiperActiveIndex === 0 }"
            slot="button-prev"
            @click="previous"
          >
            <i class="fas fa-arrow-left"></i>
          </div>
          <div
            class="swiper-nav swiper-nav--next"
            slot="button-next"
            @click="next"
          >
            <i class="fas fa-arrow-right"></i>
          </div>
        </div>
      </div>
      <div>
        <client-only>
          <div class="d-none d-md-block fade"></div>
          <swiper-container
              ref="swiper"
              class="swiper mx-4 ms-md-3 me-md-0 mw-100"
              :options="swiperOption"
              @swiperslidechange="slideChange"
          >
            <swiper-slide
                v-for="product in getProducts"
                :key="product.id"
                class="slide-product ps-md-4"
            >
              <RouterLink
                  class="undecorated-RouterLink"
                  :to="{
                name: 'studio-product',
                params: { product_id: product.id },
              }"
              >
                <StudioProductCard :product="product" :animated="false" />
              </RouterLink>
            </swiper-slide>
          </swiper-container>
        </client-only>
      </div>
    </div>

    <div class="card pad mt-4" v-if="studio.has_events">
      <div class="row">
        <div
          class="col-12 d-flex flex-row align-items-center justify-content-between"
        >
          <h2>{{ $t('STUDIO.SECTIONS.EVENTS') }}</h2>
          <RouterLink
            class="undecorated-RouterLink"
            :to="{
              name: 'studio-calendar',
            }"
          >
            <HMButton color="brand" class="pull-right">
              {{ $t('EVENTS.BUTTON.VIEW_ALL_EVENTS') }}
            </HMButton>
          </RouterLink>
        </div>
        <div class="d-none d-md-block col-12 mt-4" v-if="calendarEvents">
          <StaticFullEventsCalendar :calendarEvents="calendarEvents" :enableScrollToDay="false"/>
        </div>
        <div v-else class="d-none d-md-block w-100 text-center">
          <HMSpinner />
        </div>
        <div class="d-block d-md-none col-12 mt-4">
          <CompactEventsCalendar :studioURL="studio.studio_url" />
        </div>
      </div>
    </div>
    <div
      v-if="studio.studio_embed1"
      id="studio-embed1"
      ref="studio-embed1"
      class="card pad mt-4 bg-brand-color py-5 px-2 px-md-4 d-flex justify-content-center align-items-center"
    ></div>
    <div
      v-if="studio.studio_embed2"
      id="studio-embed2"
      ref="studio-embed2"
      class="card pad mt-4 py-5 px-2 px-md-4 d-flex justify-content-center align-items-center"
    ></div>
    <div class="py-6"></div>
  </div>
</template>

<script>
import CompactEventsCalendar from '@/components/events/CompactEventsCalendar.vue';
import StudioProductCard from '@/components/products/StudioProductCard.vue';
import StaticFullEventsCalendar from '@/components/events/StaticFullEventsCalendar.vue';
import StudioTemplateMixin from '@/mixins/StudioTemplateMixin';
import { errorToMessage } from '@/utils/errors';
import { mapState } from 'pinia';
import { useStudioStore } from '@/stores';

const INITIAL_SLIDE = 0;

export default {
  name: 'StudioHomeTemplateCards',
  mixins: [StudioTemplateMixin],
  components: {
    StudioProductCard,
    StaticFullEventsCalendar,
    CompactEventsCalendar,
  },
  computed: {
    ...mapState(useStudioStore, {
      calendarEvents: 'studioEvents',
    }),
    getProducts() {
      return this.sortedProducts;
    },
    swiper() {
      return this.$refs.swiper.swiper;
    },
    studioURL() {
      return this.$studioURL;
    },
  },
  data() {
    return {
      swiperActiveIndex: INITIAL_SLIDE,
      swiperOption: {
        initialSlide: INITIAL_SLIDE,
        slidesPerView: 4,
        spaceBetween: 20,
        loop: true,
        // effect: 'coverflow',
        // coverflowEffect: {
        //   rotate: 0,
        //   stretch: 0,
        //   depth: 0,
        //   modifier: 1,
        //   slideShadows: false,
        // },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          480: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        },
      },
    };
  },
  methods: {
    slideChange() {
      this.swiperActiveIndex = this.swiper?.activeIndex;
    },
    slideTo(index) {
      this.swiper.slideTo(index, 1000, false);
    },
    next() {
      this.swiper.slideNext();
    },
    previous() {
      this.swiper.slidePrev();
    },
    async loadEvents() {
      this.isLoading = true;
      try {
        await useStudioStore().getStudioEvents(this.$studioURL);
      } catch (error) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'Failed to load studio events',
          text: errorToMessage(error.response, `Request failed: ${error}`),
          duration: -1, // stay ON
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.loadEvents();
    if (this.studio.studio_embed1) {
      const scriptEl = document
        .createRange()
        .createContextualFragment(this.studio.studio_embed1);
      this.$refs['studio-embed1'].append(scriptEl);
    }
    if (this.studio.studio_embed2) {
      const scriptEl = document
        .createRange()
        .createContextualFragment(this.studio.studio_embed2);
      this.$refs['studio-embed2'].append(scriptEl);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.welcome-video-container {
  overflow: hidden;
  :deep(iframe) {
    border-radius: $border-radius;
  }
}

.full-width-row {
  width: 100vw;
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
}

.img-about {
  width: 100%;
  max-height: 600px;
  object-fit: cover;
  top: -1px;
  z-index: -1;
  border-radius: 20px;
  object-position: 80% 20%;

  @include media-breakpoint-down(sm) {
    max-height: 240px;
  }
}

.profile-photo {
  width: 180px;
  height: 180px;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 50%;
  border: 5px solid white;
  background: white;

  @include media-breakpoint-down(sm) {
    width: 110px;
    height: 110px;
  }
}

.card {
  border: none;
  border-radius: 20px;
  overflow: hidden;

  &.pad {
    padding: 3rem;
    @include media-breakpoint-down(sm) {
      padding: 1.5rem;
    }
  }
}

:deep(.studio-header-content) {
  max-height: unset !important;
}

:deep(.swiper-container) {
  overflow: visible;
}

:deep(.swiper-slide) {
  @include media-breakpoint-down(sm) {
    width: 81% !important;
  }
}

.tall {
  :deep(.studio-header-content) {
    max-height: 600px;
    min-height: 240px;
  }
}

.fade {
  top: 100px;
  right: -1px;
  width: 100px;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 98%);
  position: absolute;
  opacity: 1;
  z-index: 5;
}

.swiper-nav {
  font-size: 1rem;
  z-index: 1;
  width: 44px;
  height: 44px;
  background-color: var(--brand-color, $coral);
  color: white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    filter: brightness(95%);
  }
  &--next {
    right: 0;
  }
  &--prev {
    left: 0;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      filter: none;
    }
  }
}

.product-nav {
  margin: 2rem 0 3rem 3rem;
  @include media-breakpoint-down(sm) {
    margin: 2rem 0 3rem 2rem;
  }
}
</style>
