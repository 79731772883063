enum ProductState {
  Published = 'Published',
  PrivateSpaces = 'Private Spaces',
  Hidden = 'Hidden',
  Draft = 'Draft',
  Archive = 'Archive',
}
export function getProductStateColor(productState: ProductState): string {
  switch (productState) {
    case ProductState.Published:
      return '#9AD67D';
    case ProductState.PrivateSpaces:
      return '#a55b32';
    case ProductState.Hidden:
      return '#7A8390';
    case ProductState.Draft:
      return '#CC9900';
    case ProductState.Archive:
      return '#8DAFB8';
    default:
      return '#FFFFFF';
  }
}
export default ProductState;
