<template>
  <div class="brand-color-template">
    <div class="row pt-3">
      <div class="col-12 col-md-6">
        <HMStudioHeader
          class="text-center no-border-radius"
          v-if="studio"
          :image="headerImage"
        />
        <HMFilePreview
          v-if="hasWelcomeVideo"
          :file="studio.welcome_video"
          class="welcome-video-container mt-4"
        />
      </div>
      <div
        class="col-12 col-md-6 text-center text-md-start ps-lg-5 mt-4 mt-md-0"
      >
        <img class="profile-photo mb-5" :src="profilePhoto" />
        <h3>{{ studio.studio_name }}</h3>
        <HMCustomContent
          v-if="
            studioDescriptionExists(studio.about_text, studio.about_text_new)
          "
          :html="studio.about_text"
          :json="studio.about_text_new"
        />
        <div class="d-none d-md-block my-5">
          <img src="/assets/images/downarrow.svg" />
        </div>
      </div>
    </div>
    <div class="d-block d-md-none row my-5">
      <div class="col-12 text-center">
        <img src="/assets/images/downarrow.svg" />
      </div>
    </div>
    <div v-if="studio.about_photo" class="full-width-row pad mt-4">
      <HMStudioHeader
        class="text-center no-border-radius img-about tall"
        :image="studio.about_photo"
      />
    </div>
    <div class="py-4"></div>
    <div class="studio-content mt-5">
      <div
        class="row pt-4"
        v-if="
          studioDescriptionExists(studio.about_studio, studio.about_studio_new)
        "
      >
        <div class="col-sm-12 col-lg-3">
          <h2>{{ $t('STUDIO.SECTIONS.ABOUT_US') }}</h2>
        </div>
        <div class="col-sm-12 col-lg-9 mt-sm-4 mt-lg-0">
          <HMCustomContent
            :html="studio.about_studio"
            :json="studio.about_studio_new"
          />
        </div>
      </div>
      <div v-if="studio && studio.products.length" class="py-5"></div>
      <div
        class="full-width-row pad mt-5"
        v-if="studio && studio.products.length"
      >
        <div class="row">
          <div class="col-3 d-none d-md-flex">
            <div class="navigation d-flex flex-row flex-nowrap me-3 me-md-5">
              <div
                class="swiper-nav swiper-nav--prev me-2"
                :class="{ disabled: swiperActiveIndex === 0 }"
                slot="button-prev"
                @click="previous"
              >
                <i class="far fa-arrow-left"></i>
              </div>
              <div
                class="swiper-nav swiper-nav--next"
                slot="button-next"
                @click="next"
              >
                <i class="far fa-arrow-right"></i>
              </div>
            </div>
          </div>
          <div class="col">
            <RouterLink
              class="undecorated-RouterLink"
              :to="{
                name: 'studio-product',
                params: { product_id: currentProduct.id },
              }"
            >
              <StudioProductCard2
                :product="currentProduct"
                :animated="false"
                :fullSize="true"
                class="product-card"
              />
            </RouterLink>
          </div>
          <div class="col-12 d-flex d-md-none mt-4 w-100">
            <div
              class="navigation d-flex flex-row flex-nowrap justify-content-center align-items-center w-100"
            >
              <div
                class="swiper-nav swiper-nav--prev me-2"
                :class="{ disabled: swiperActiveIndex === 0 }"
                slot="button-prev"
                @click="previous"
              >
                <i class="far fa-arrow-left"></i>
              </div>
              <div
                class="swiper-nav swiper-nav--next"
                slot="button-next"
                @click="next"
              >
                <i class="far fa-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="studio.has_events" class="py-5"></div>
      <div class="row mt-5 mb-6" v-if="studio.has_events">
        <div class="col-12 mt-4 position-relative">
          <CompactEventsCalendar
            class="event-container no-gutters"
            :studioURL="studio.studio_url"
          />
          <div class="events-title-overlay">
            <h1>{{ $t('STUDIO.SECTIONS.EVENTS') }}</h1>
          </div>
          <div class="events-footer-overlay">
            <RouterLink
              class="undecorated-RouterLink"
              :to="{
                name: 'studio-calendar',
              }"
            >
              <HMButton
                color="white-border"
                class="btn-rounded mt-3 btn-view-all-events"
              >
                <span class="px-5 py-2 text-uppercase w-100 text-center">
                  {{ $t('EVENTS.BUTTON.VIEW_ALL_EVENTS') }}
                </span>
              </HMButton>
            </RouterLink>
          </div>
        </div>
      </div>
      <div
        class="py-4"
        v-if="studio.studio_embed1 || studio.studio_embed2"
      ></div>
      <div
        v-if="studio.studio_embed1"
        id="studio-embed1"
        ref="studio-embed1"
        class="full-width-row mt-4 bg-brand-color py-5 px-2 px-md-4 d-flex justify-content-center"
      ></div>
      <div
        v-if="studio.studio_embed2"
        id="studio-embed2"
        ref="studio-embed2"
        class="full-width-row mt-4 py-5 px-2 px-md-4 d-flex justify-content-center"
      ></div>
      <div class="py-4"></div>
    </div>
  </div>
</template>

<script>
import CompactEventsCalendar from '@/components/events/CompactEventsCalendar.vue';
import StudioProductCard2 from '@/components/products/StudioProductCard2.vue';
import StudioTemplateMixin from '@/mixins/StudioTemplateMixin';

export default {
  name: 'StudioHomeTemplateBrandColor',
  mixins: [StudioTemplateMixin],
  components: {
    CompactEventsCalendar,
    StudioProductCard2,
  },
  computed: {
    getProducts() {
      return this.sortedProducts;
    },
    swiper() {
      return this.$refs.swiper?.swiper;
    },
    studioURL() {
      return this.$studioURL;
    },
    currentProduct() {
      return this.getProducts[this.swiperActiveIndex];
    },
  },
  data() {
    return {
      swiperActiveIndex: 0,
    };
  },
  methods: {
    next() {
      if (this.swiperActiveIndex < this.getProducts.length - 1) {
        this.swiperActiveIndex += 1;
      }
    },
    previous() {
      if (this.swiperActiveIndex > 0) {
        this.swiperActiveIndex -= 1;
      }
    },
  },
  mounted() {
    if (this.studio.studio_embed1) {
      const scriptEl = document
        .createRange()
        .createContextualFragment(this.studio.studio_embed1);
      this.$refs['studio-embed1'].append(scriptEl);
    }
    if (this.studio.studio_embed2) {
      const scriptEl = document
        .createRange()
        .createContextualFragment(this.studio.studio_embed2);
      this.$refs['studio-embed2'].append(scriptEl);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.brand-color-template * {
  color: white;
}

.brand-color-template {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: uppercase;
  }
}

.welcome-video-container {
  overflow: hidden;
}

.full-width-row {
  width: 100vw;
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
  z-index: 1;

  &.pad {
    padding: 0 80px;

    @include media-breakpoint-down(sm) {
      padding: 0 20px;
    }
  }
}

.img-about {
  width: 100%;
  max-height: 600px;
  object-fit: cover;
  object-position: bottom;
  position: relative;
  top: -1px;
  z-index: -1;

  @include media-breakpoint-down(sm) {
    max-height: 240px;
  }
}

.profile-photo {
  width: 86px;
  height: 86px;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 50%;
  border: 5px solid white;
  background: white;
}

:deep(.embed-responsive-header::before) {
  padding-top: 56.25%;

  @include media-breakpoint-down(lg) {
    padding-top: 56.25%;
  }
}

:deep(.vc-container) {
  background-color: var(
    --brand-color-with-opacity-05,
    rgba($light-bubblegum, 0.5)
  );
  border-radius: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

:deep(.vc-container *) {
  color: white !important;
  border-color: white !important;
}

:deep(.vc-title:before) {
  color: white !important;
}

:deep(.vc-arrow) {
  &:hover {
    background-color: transparent;
  }
}

:deep(.calendar-navigation-btn) {
  &:hover {
    filter: brightness(95%);
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

:deep(.compact-events-list) {
  background-color: var(
    --brand-color-with-opacity-05,
    rgba($light-bubblegum, 0.5)
  );
  border-radius: 0;
  height: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

:deep(.vc-dot) {
  background-color: white !important;
}

:deep(.compact-events-list) * {
  color: white !important;
}

.swiper-nav {
  font-size: 1rem;
  z-index: 1;
  width: 44px;
  height: 44px;
  background-color: white;
  color: var(--brand-color, $coral);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  i {
    color: var(--brand-color, $coral);
  }
  &:hover {
    filter: brightness(95%);
  }
  &--next {
    right: 0;
  }
  &--prev {
    left: 0;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      filter: none;
    }
  }
}

.product-nav {
  margin: 2rem 6rem 3rem 3rem;
  @include media-breakpoint-down(sm) {
    margin: 2rem 0 3rem 2rem;
  }
}

.product-card {
  max-width: 1024px;
}

.event-container {
  background-color: white;
}

.events-title-overlay {
  position: absolute;
  left: 50% !important;
  transform: translate(-50%, 0) !important;
  top: -1.85vw;
  h1 {
    font-size: 3vw;
  }
}

.events-footer-overlay {
  position: absolute;
  left: 50% !important;
  transform: translate(-50%, 0) !important;
  bottom: -28px;
}

.btn-view-all-events {
  min-width: 300px;
}

.tall {
  :deep(.studio-header-content) {
    max-height: 600px;
    min-height: 240px;
  }
}
</style>
