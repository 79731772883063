<template>
  <div
    class="studio-home"
    :class="`${selectedTemplate.config.studioBackgroundColorClass} ${
      selectedTemplate.config.studioTopPadding ? 'pad' : ''
    }`"
  >
    <component
      v-if="studioStore.currentStudio"
      class="container"
      :is="selectedTemplate.component"
    ></component>
    <AddToHomeScreenBanner class="d-md-none add-to-home-screen-banner" />
  </div>
</template>

<script setup lang="ts">
import { useStudioStore } from '@/stores';

definePageMeta({
  name: 'studio-home-page',

  isStudio: true,
});

const studioStore = useStudioStore();
const selectedTemplate = studioStore.selectedTemplate;

</script>

<script lang="ts">
import AddToHomeScreenBanner from '@/components/pwa/AddToHomeScreenBanner.vue';
import StudioHomeTemplateClassic from '@/components/studio-templates/StudioHomeTemplateClassic.vue';
import StudioHomeTemplateModern from '@/components/studio-templates/StudioHomeTemplateModern.vue';
import StudioHomeTemplateCards from '@/components/studio-templates/StudioHomeTemplateCards.vue';
import StudioHomeTemplateBrandColor from '@/components/studio-templates/StudioHomeTemplateBrandColor.vue';
import StudioHomeTemplateProductBanner from '@/components/studio-templates/StudioHomeTemplateProductBanner.vue';

export default {
  name: 'StudioHome',
  components: {
    AddToHomeScreenBanner,
    StudioHomeTemplateClassic,
    StudioHomeTemplateModern,
    StudioHomeTemplateCards,
    StudioHomeTemplateBrandColor,
    StudioHomeTemplateProductBanner,
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.studio-home {
  &.pad {
    @include media-breakpoint-up(sm) {
      padding-top: $shop-topbar-height;
    }
  }
}

.add-to-home-screen-banner {
  bottom: 70px;
}
</style>
