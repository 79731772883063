<template>
  <div>
    <div class="row">
      <div class="col-11 ms-3 ms-md-0 col-xl-6 m-auto m-md-0">
        <HMStudioHeader
          class="text-center no-border-radius"
          :image="headerImage"
          :profilePhoto="profilePhoto"
          profilePosition="right"
        />
      </div>
      <div class="col-12 offset-xl-1 col-xl-5">
        <h1 class="pt-4 pt-md-0">
          {{
            $t('STUDIO.SECTIONS.WELCOME_TO', {
              studio_name: studio.studio_name,
            })
          }}
        </h1>
        <HMCustomContent
          v-if="
            studioDescriptionExists(studio.about_text, studio.about_text_new)
          "
          :html="studio.about_text"
          :json="studio.about_text_new"
        />
      </div>
    </div>
    <div class="row mt-6" v-if="hasWelcomeVideo">
      <div class="col-12">
        <HMFilePreview
          :file="studio.welcome_video"
          class="welcome-video-container rounded"
        />
      </div>
    </div>
    <div v-if="studio.about_photo" class="full-width-row mt-6">
      <HMStudioHeader
        class="text-center no-border-radius img-about"
        :image="studio.about_photo"
      />
    </div>
    <div class="studio-content mt-6">
      <div
        class="row"
        v-if="
          studioDescriptionExists(studio.about_studio, studio.about_studio_new)
        "
      >
        <div class="col-sm-12 col-lg-3">
          <h2>{{ $t('STUDIO.SECTIONS.ABOUT_US') }}</h2>
        </div>
        <div class="col-sm-12 col-lg-9 mt-sm-4 mt-lg-0">
          <HMCustomContent
            :html="studio.about_studio"
            :json="studio.about_studio_new"
          />
        </div>
        <div class="col-12 mt-4">
          <hr />
        </div>
      </div>
      <div class="row mt-4" v-if="studio && studio.products.length">
        <div class="col-12 col-lg-3">
          <h2>{{ $t('STUDIO.SECTIONS.RECENT_PRODUCTS') }}</h2>
        </div>
        <div class="col-12 col-lg-9">
          <div class="row d-none d-md-block">
            <div class="col-12 d-flex flex-row justify-content-end">
              <RouterLink
                class="undecorated-RouterLink"
                :to="{
                  name: 'studio-shop',
                }"
              >
                <HMButton color="brand" class="pull-right no-border-radius">
                  {{ $t('GENERAL.BUTTON.VIEW_SHOP') }}
                </HMButton>
              </RouterLink>
            </div>
          </div>
          <div class="row mt-4">
            <div
              class="col-12 col-md-6"
              v-for="product in getProducts"
              :key="product.id"
            >
              <RouterLink
                class="undecorated-RouterLink"
                :to="{
                  name: 'studio-product',
                  params: { product_id: product.id },
                }"
              >
                <StudioProductCard
                  class="no-border-radius"
                  :product="product"
                />
              </RouterLink>
            </div>
          </div>
          <div class="row d-block d-md-none mt-4">
            <div class="col-12 d-flex flex-row justify-content-end">
              <RouterLink
                class="undecorated-RouterLink w-100"
                :to="{
                  name: 'studio-shop',
                }"
              >
                <HMButton
                  color="brand w-100"
                  class="pull-right no-border-radius text-center"
                >
                  <span class="w-100 text-center">
                    {{ $t('GENERAL.BUTTON.VIEW_SHOP') }}
                  </span>
                </HMButton>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2" v-if="studio.has_events">
        <div class="col-12">
          <hr />
        </div>
      </div>
      <div class="row mt-4" v-if="studio.has_events">
        <div
          class="col-12 d-flex flex-row align-items-center justify-content-between"
        >
          <h2>{{ $t('STUDIO.SECTIONS.EVENTS') }}</h2>
          <RouterLink
            class="undecorated-RouterLink"
            :to="{
              name: 'studio-calendar',
            }"
          >
            <HMButton color="brand" class="pull-right no-border-radius">
              {{ $t('EVENTS.BUTTON.VIEW_ALL_EVENTS') }}
            </HMButton>
          </RouterLink>
        </div>
        <div class="col-12 mt-4">
          <CompactEventsCalendar :studioURL="studio.studio_url" />
        </div>
      </div>
      <div
        v-if="studio.studio_embed1"
        id="studio-embed1"
        ref="studio-embed1"
        class="full-width-row mt-4 bg-brand-color py-5 px-2 px-md-4 d-flex justify-content-center"
      ></div>
      <div
        v-if="studio.studio_embed2"
        id="studio-embed2"
        ref="studio-embed2"
        class="full-width-row mt-4 py-5 px-2 px-md-4 d-flex justify-content-center"
      ></div>
      <div class="py-4"></div>
    </div>
  </div>
</template>

<script>
import CompactEventsCalendar from '@/components/events/CompactEventsCalendar.vue';
import StudioProductCard from '@/components/products/StudioProductCard.vue';
import StudioTemplateMixin from '@/mixins/StudioTemplateMixin';

export default {
  name: 'StudioHomeTemplateModern',
  mixins: [StudioTemplateMixin],
  components: {
    CompactEventsCalendar,
    StudioProductCard,
  },
  computed: {
    getProducts() {
      return this.getShopPreviewProducts.slice(0, 2);
    },
  },
  methods: {},
  mounted() {
    if (this.studio.studio_embed1) {
      const scriptEl = document
        .createRange()
        .createContextualFragment(this.studio.studio_embed1);
      this.$refs['studio-embed1'].append(scriptEl);
    }
    if (this.studio.studio_embed2) {
      const scriptEl = document
        .createRange()
        .createContextualFragment(this.studio.studio_embed2);
      this.$refs['studio-embed2'].append(scriptEl);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.welcome-video-container {
  overflow: hidden;
  :deep(iframe) {
    border-radius: $border-radius;
  }
}

.full-width-row {
  width: 100vw;
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
}

.img-about {
  width: 100%;
  max-height: 600px;
  object-fit: cover;
  object-position: bottom;
  position: relative;
  top: -1px;
  z-index: -1;

  @include media-breakpoint-down(sm) {
    max-height: 240px;
  }
}

:deep(.studio-header-content) {
  max-height: 600px !important;
}
</style>
