<template>
  <div
    class="studio-product-card d-flex flex-column flex-md-row"
    :class="{ 'flex-md-row-reverse': reverse }"
    v-bind="$attrs"
  >
    <div class="left">
      <HMFixedAspectRatioContainer>
        <ProductThumbnail2
          class="no-border-radius"
          :product="product"
          :showNewTag="false"
          @click="$emit('click')"
        />
      </HMFixedAspectRatioContainer>
    </div>
    <div class="d-flex flex-column align-items-start right p-5 bg-white">
      <div class="description flex-grow-1 text-truncate">
        <div
          class="d-flex flex-column justify-content-center align-items-start"
        >
          <span class="mb-2">{{ productTypeText }}</span>
          <h2 class="product-name-container m-0">
            <HMTextClamp
              autoresize
              :max-lines="2"
              :text="product.product_name"
            >
            </HMTextClamp>
          </h2>
          <div>
            {{ getPricingText }}
          </div>
          <HMButton
            color="brand"
            class="mt-3 no-border-radius"
            @click="$emit('click')"
          >
            <span class="px-5 py-2 text-uppercase w-100 text-center">
              {{ $t('PRODUCTS.BUTTON.LEARN_MORE') }}
            </span>
          </HMButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import _ from 'lodash-es';
import moment from 'moment';
import {
  getProductStateColor,
} from '@/typings/enums/ProductState';
import { getProductTypeTranslations } from '@/utils/products';
import ProductThumbnail2 from '@/components/products/ProductThumbnail2.vue';
import { mapState } from 'pinia';
import { useStudioStore } from '@/stores';

export default {
  name: 'StudioProductCard2',
  components: { ProductThumbnail2 },
  props: {
    product: {
      type: Object as () => ProductResponse,
      required: true,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    date: function (value: string): string {
      if (!value) return '';
      return moment(value).format('MMM DD, yyyy');
    },
  },
  computed: {
    ...mapState(useStudioStore, {
      currency: 'currency',
    }),
    color(): string {
      return getProductStateColor(this.product.productState);
    },
    getProductType() {
      return getProductTypeTranslations(this.product.product_type);
    },
    productTypeText() {
      return this.$t(getProductTypeTranslations(this.product.product_type));
    },
    getPricingText() {
      if (this.product.pricing_type === 'free') {
        return this.$t('PRODUCTS.LABELS.FREE');
      }
      if (this.product.pricing_type === 'fixed') {
        return this.$formatCurrency(this.product.price || 0, this.currency);
      }
      if (this.product.pricing_type === 'recurring') {
        let pricingText;
        let period;
        if (this.product.recurring_type !== 'multiple') {
          period = this.$t(
            `PRODUCTS.LABELS.RECURRING_${this.product.recurring_type.toUpperCase()}`
          );
          pricingText = `${this.$formatCurrency(
            this.product.price || 0,
            this.currency
          )}`;
        } else {
          const prefix = this.$t('PRODUCTS.LABELS.FROM');
          const arr = _.values(
            _.transform(
              this.product.recurring_options,
              (result, value, key) => {
                result[key] = { ...value, period: key };
              }
            )
          );
          const onlyAvailable = arr.filter(opt => opt.available);
          const lowestOption = _.minBy(onlyAvailable, option =>
            parseFloat(option.price)
          );
          period = this.$t(
            `PRODUCTS.LABELS.RECURRING_${lowestOption.period.toUpperCase()}`
          );
          pricingText = `${prefix} ${this.$formatCurrency(
            lowestOption.price || 0,
            this.currency
          )}`;
        }

        if (this.isGift) {
          return pricingText;
        } else {
          return `${pricingText}/${period}`;
        }
      }
      return 'ERROR';
    },
    hidePrice() {
      return this.static || this.product.purchased;
    },
    showWishPriceEnabled() {
      return this.product.wish_price_enabled && !this.hidePrice && !this.isGift;
    },
    hasTags() {
      return this.product?.tags?.length;
    },
  },
  methods: {
    tagClicked(tag) {
      console.log(tag);
      window?.alert(tag.name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';
.studio-product-card {
  position: relative;

  .left,
  .right {
    max-width: 50%;
    width: 50%;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      width: 100%;
    }
  }

  &.new {
    padding: 2rem 0 0 2rem;
  }

  .description {
    position: relative;
    bottom: 0;
    left: 0;
    padding-top: 1rem;
    border-top-right-radius: 6px;
    width: 100%;
  }

  .product-name-container {
    white-space: normal;
    line-height: 1.5;
    max-width: 33vw;
    font-size: 2.5vw;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      font-size: 1.5rem;
    }
  }

  .heart {
    color: $coral;
  }
  .dot {
    margin-right: 0.25rem;
    margin-top: 0.3rem;
    font-size: 0.5rem;
  }
  .embed-responsive {
    overflow: visible;
  }

  .product-progress-wrapper {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .product-progress {
    width: 100%;
    border-radius: 6px;
    background-color: var(--brand-color-with-opacity-03);
  }

  .progress-bar {
    height: 18px;
    border-radius: 9px;
    background-color: var(--brand-color);
  }

  .footer {
    height: 3rem;
  }
}
</style>
