<template>
  <div>
    <div class="row">
      <div class="col-12">
        <HMStudioHeader
          class="text-center"
          v-if="studio"
          :image="headerImage"
          :profilePhoto="profilePhoto"
        />
      </div>
    </div>
    <div class="studio-content mt-5">
      <div class="row">
        <div class="col-12">
          <h1 class="text-center m-0">{{ studio.studio_name }}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <HMTipButton
            v-if="studio.tip_jar_enabled"
            color="outline-gray"
            class="mt-4"
          ></HMTipButton>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <hr />
        </div>
      </div>
      <div
        class="row mt-4"
        v-if="
          studioDescriptionExists(studio.about_text, studio.about_text_new) ||
          studio.welcome_video
        "
      >
        <div class="col-sm-12 col-lg-3">
          <h2>{{ $t('STUDIO.SECTIONS.WELCOME') }}</h2>
        </div>
        <div class="col-sm-12 col-lg-9 mt-sm-4 mt-lg-0">
          <HMFilePreview
            v-if="studio.welcome_video"
            :file="studio.welcome_video"
            :class="{ 'mb-4': studio.about_text }"
            class="welcome-video-container rounded"
          />
          <HMCustomContent
            :html="studio.about_text"
            :json="studio.about_text_new"
          />
        </div>
        <div class="col-12 mt-4">
          <hr />
        </div>
      </div>
      <div
        class="row mt-4"
        v-if="
          studioDescriptionExists(studio.about_studio, studio.about_studio_new)
        "
      >
        <div class="col-sm-12 col-lg-3">
          <h2>{{ $t('STUDIO.SECTIONS.ABOUT') }}</h2>
        </div>
        <div class="col-sm-12 col-lg-9 mt-sm-4 mt-lg-0">
          <HMCustomContent
            :html="studio.about_studio"
            :json="studio.about_studio_new"
          />
        </div>
        <div class="col-12">
          <hr />
        </div>
      </div>
      <div class="row mt-4" v-if="studio && studio.products.length">
        <div
          class="col-12 d-flex flex-row align-items-center justify-content-between"
        >
          <h2>{{ $t('STUDIO.SECTIONS.SHOP') }}</h2>
          <NuxtLink
            class="undecorated-router-link"
            :to="{
              name: 'studio-shop',
            }"
          >
            <HMButton color="outline-gray" class="pull-right">
              {{ $t('GENERAL.BUTTON.VIEW_SHOP') }}
            </HMButton>
          </NuxtLink>
        </div>
        <div class="col-12 mt-4">
          <div class="row">
            <div
              class="col-sm-6 col-md-3 px-3"
              v-for="product in getShopPreviewProducts"
              :key="product.id"
            >
              <NuxtLink
                class="undecorated-router-link"
                :to="{
                  name: 'studio-product',
                  params: { product_id: product.id },
                }"
              >
                <StudioProductCard :product="product" size="sm" />
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2" v-if="studio.has_events">
        <div class="col-12">
          <hr />
        </div>
      </div>
      <div class="row mt-4" v-if="studio.has_events">
        <div
          class="col-12 d-flex flex-row align-items-center justify-content-between"
        >
          <h2>{{ $t('STUDIO.SECTIONS.EVENTS') }}</h2>
          <NuxtLink
            class="undecorated-router-link"
            :to="{
              name: 'studio-calendar',
            }"
          >
            <HMButton color="outline-gray" class="pull-right">
              {{ $t('EVENTS.BUTTON.VIEW_ALL_EVENTS') }}
            </HMButton>
          </NuxtLink>
        </div>
        <div class="col-12 mt-4">
          <CompactEventsCalendar :studioURL="studio.studio_url" />
        </div>
      </div>
      <div
        v-if="studio.studio_embed1"
        id="studio-embed1"
        ref="studio-embed1"
        class="mt-5 py-5 px-2 px-md-4 d-flex justify-content-center"
      ></div>
      <div
        v-if="studio.studio_embed2"
        id="studio-embed2"
        ref="studio-embed2"
        class="mt-5 py-5 px-2 px-md-4 d-flex justify-content-center"
      ></div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
// import HMButton from '@/web/components/common/HMButton';
// import CompactEventsCalendar from '@/web/components/events/CompactEventsCalendar';
// import StudioProductCard from '@/web/components/products/StudioProductCard';
// import HMStudioHeader from '@/web/components/common/HMStudioHeader';
// import HMTipButton from '@/web/components/common/HMTipButton';
// import HMCustomContent from '@/components/common/HMCustomContent';
import StudioTemplateMixin from '@/mixins/StudioTemplateMixin';

export default {
  name: 'StudioHomeTemplateClassic',
  mixins: [StudioTemplateMixin],
  components: {
    // HMCustomContent
    // HMTipButton,
    // HMButton,
    // HMStudioHeader,
    // CompactEventsCalendar,
    // StudioProductCard,
  },
  computed: {},
  methods: {},
  mounted() {
    if (this.studio.studio_embed1) {
      const scriptEl = document
        .createRange()
        .createContextualFragment(this.studio.studio_embed1);
      this.$refs['studio-embed1'].append(scriptEl);
    }
    if (this.studio.studio_embed2) {
      const scriptEl = document
        .createRange()
        .createContextualFragment(this.studio.studio_embed2);
      this.$refs['studio-embed2'].append(scriptEl);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.studio-home {
  @include media-breakpoint-up(sm) {
    margin-top: $shop-topbar-height;
  }
}

.welcome-video-container {
  overflow: hidden;
  :deep(iframe) {
    border-radius: $border-radius;
  }
}

.add-to-home-screen-banner {
  bottom: 70px;
}
</style>
