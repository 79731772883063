<template>
  <div>
    <div class="full-width-row">
      <HMStudioHeader v-if="studio" :image="headerImage" />
      <div class="header-container text-center">
        <h1>{{ studio.studio_name }}</h1>
        <HMCustomContent
          class="mt-4"
          v-if="
            studioDescriptionExists(studio.about_text, studio.about_text_new)
          "
          :html="studio.about_text"
          :json="studio.about_text_new"
        />
        <div class="profile-photo-container">
          <img class="profile-photo" :src="profilePhoto" />
        </div>
        <HMFilePreview
          v-if="studio.welcome_video"
          :file="studio.welcome_video"
          class="welcome-video-container mt-5"
        />
      </div>
    </div>

    <div class="row my-4">
      <div class="col text-center">
        <h2>{{ $t('STUDIO.SECTIONS.SHOP_THE_PRODUCTS') }}</h2>
      </div>
    </div>

    <div
      class="full-width-row mb-4"
      v-for="(product, index) in getProducts"
      :key="product.id"
    >
      <NuxtLink
        class="undecorated-router-link"
        :to="{
          name: 'studio-product',
          params: { product_id: product.id },
        }"
      >
        <StudioProductCard3 :product="product" :reverse="index % 2 === 1" />
      </NuxtLink>
    </div>
    <div
      class="row pt-6"
      v-if="
        studioDescriptionExists(studio.about_studio, studio.about_studio_new)
      "
    >
      <div class="col-sm-12 col-lg-3">
        <h2>{{ $t('STUDIO.SECTIONS.ABOUT_US') }}</h2>
      </div>
      <div class="col-sm-12 col-lg-9 mt-sm-4 mt-lg-0">
        <HMCustomContent
          :html="studio.about_studio"
          :json="studio.about_studio_new"
        />
      </div>
    </div>
    <div
      class="card no-border-radius my-6 p-3 p-md-5 border-0"
      v-if="studio.has_events"
    >
      <div class="row">
        <div
          class="col-12 d-flex flex-row align-items-center justify-content-between"
        >
          <h2>{{ $t('STUDIO.SECTIONS.EVENTS') }}</h2>
          <div class="d-none d-md-block">
            <RouterLink
              class="undecorated-router-link"
              :to="{
                name: 'studio-calendar',
              }"
            >
              <HMButton
                color="brand"
                class="no-border-radius pull-right text-uppercase"
              >
                <span class="mx-3 py-2">{{
                  $t('EVENTS.BUTTON.VIEW_ALL_EVENTS')
                }}</span>
              </HMButton>
            </RouterLink>
          </div>
        </div>
        <div class="d-none d-md-block col-12 mt-4" v-if="calendarEvents">
          <StaticFullEventsCalendar :calendarEvents="calendarEvents" :enableScrollToDay="false"/>
        </div>
        <div v-else class="d-none d-md-block w-100 text-center">
          <HMSpinner />
        </div>
        <div class="d-block d-md-none col-12 mt-4">
          <CompactEventsCalendar :studioURL="studio.studio_url" />
        </div>
        <div class="d-block d-md-none col-12 mt-4">
          <RouterLink
            class="undecorated-router-link"
            :to="{
              name: 'studio-calendar',
            }"
          >
            <HMButton
              color="brand"
              class="no-border-radius text-center text-uppercase w-100"
            >
              <span class="mx-3 py-2 w-100">{{
                $t('EVENTS.BUTTON.VIEW_ALL_EVENTS')
              }}</span>
            </HMButton>
          </RouterLink>
        </div>
      </div>
    </div>
    <div v-if="studio.about_photo" class="full-width-row mt-6 mb-4">
      <HMStudioHeader
        class="text-center no-border-radius img-about tall"
        :image="studio.about_photo"
      />
    </div>
    <div
      v-if="studio.studio_embed1"
      id="studio-embed1"
      ref="studio-embed1"
      class="mt-5 py-5 px-2 px-md-4 d-flex justify-content-center"
    ></div>
    <div
      v-if="studio.studio_embed2"
      id="studio-embed2"
      ref="studio-embed2"
      class="mt-5 py-5 px-2 px-md-4 d-flex justify-content-center"
    ></div>
    <div class="py-4"></div>
  </div>
</template>

<script>
import StaticFullEventsCalendar from '@/components/events/StaticFullEventsCalendar.vue';
import CompactEventsCalendar from '@/components/events/CompactEventsCalendar.vue';
import StudioTemplateMixin from '@/mixins/StudioTemplateMixin';

import StudioProductCard3 from '@/components/products/StudioProductCard3.vue';
import { errorToMessage } from '@/utils/errors';
import { mapState } from 'pinia';
import { useStudioStore } from '@/stores';

export default {
  name: 'StudioHomeTemplateProductBanner',
  mixins: [StudioTemplateMixin],
  components: {
    StudioProductCard3,
    StaticFullEventsCalendar,
    CompactEventsCalendar,
  },
  computed: {
    ...mapState(useStudioStore, {
      calendarEvents: 'studioEvents',
    }),
    getProducts() {
      return this.sortedProducts.slice(0, 3);
    },
    swiper() {
      return this.$refs.swiper?.swiper;
    },
    studioURL() {
      return this.$studioURL;
    },
  },
  data() {
    return {};
  },
  methods: {
    async loadEvents() {
      this.isLoading = true;
      try {
        await useStudioStore().getStudioEvents(this.studioURL);
      } catch (error) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'Failed to load studio events',
          text: errorToMessage(error.response, `Request failed: ${error}`),
          duration: -1, // stay ON
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.loadEvents();
    if (this.studio.studio_embed1) {
      const scriptEl = document
        .createRange()
        .createContextualFragment(this.studio.studio_embed1);
      this.$refs['studio-embed1'].append(scriptEl);
    }
    if (this.studio.studio_embed2) {
      const scriptEl = document
        .createRange()
        .createContextualFragment(this.studio.studio_embed2);
      this.$refs['studio-embed2'].append(scriptEl);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.welcome-video-container {
  overflow: hidden;
}

.full-width-row {
  width: 100vw;
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
}

.header-container {
  position: relative;
  left: 50% !important;
  transform: translate(-50%, -20%) !important;
  max-width: 668px;
  background-color: white;
  padding: 4rem;
  z-index: 2;
  margin-bottom: -3%;

  .profile-photo-container {
    position: absolute;
    right: -50px;
    top: -50px;
    box-shadow: -2px 2px 12px #bcbcbc;
    border-radius: 50px;
  }

  @include media-breakpoint-down(sm) {
    padding: 2rem;
    transform: translate(-50%, -7%) !important;
    max-width: 80%;

    .profile-photo-container {
      position: absolute;
      right: -31px;
      top: -31px;
      box-shadow: -2px 2px 12px #bcbcbc;
      border-radius: 50px;
    }
  }
}

.img-about {
  width: 100%;
  max-height: 600px;
  object-fit: cover;
  top: -1px;
  z-index: -1;
  border-radius: 20px;
  object-position: 80% 20%;

  @include media-breakpoint-down(sm) {
    max-height: 240px;
  }
}

.profile-photo {
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 50%;
  border: 5px solid white;
  background: white;

  @include media-breakpoint-down(sm) {
    width: 62px;
    height: 62px;
  }
}

.tall {
  :deep(.studio-header-content) {
    max-height: 600px;
    min-height: 240px;
  }
}

:deep(.btn) {
  &:active,
  &:focus {
    border-color: transparent !important;
    outline-color: transparent !important;
    box-shadow: none !important;
  }
}
</style>
