<template>
  <div>
    <div v-show="!!weeks.length">
      <div ref="daysSwiper"></div>
      <div class="days-swiper" :class="`template-${templateType}`">
        <div class="full-bg"></div>
        <div class="header bg-brand-color">
          <span class="header-title">
            {{ calendarTitle }}
          </span>
        </div>
        <swiper-container
          class="swiper"
          ref="calendarSwiper"
        >
          <swiper-slide v-for="(week, weekIndex) in weeks" :key="week.weekId">
            <div class="week" v-if="showWeek(weekIndex)">
              <div
                v-for="day in week.weekDays"
                :key="day.date"
                class="day"
                @click="!day.isDisabled && selectDate(day)"
                :class="{
                  'day--active': day.dayId === activeDate,
                  'day--disabled': day.isDisabled,
                  'day--enabled': !day.isDisabled,
                  '': day.dayId === activeDate,
                }"
              >
                <span class="day-week-day hide-on-mobile">
                  {{ day.weekDay }}
                </span>
                <span class="day-week-day show-only-mobile">
                  {{ day.weekDayShort }}
                </span>
                <span class="day-day">
                  {{ day.day }}
                </span>
              </div>
            </div>
          </swiper-slide>
          <div
            class="swiper-button-prev-custom calendar-navigation-btn prev"
            slot="container-start"
            @click="swiper.swiper.slidePrev()"
          >
            <i class="far fa-long-arrow-left"></i>
          </div>
          <div
            class="swiper-button-next-custom calendar-navigation-btn next"
            slot="container-end"
            @click="swiper.swiper.slideNext()"
          >
            <i class="far fa-long-arrow-right"></i>
          </div>
        </swiper-container>
      </div>
      <div class="events-list">
        <div
          class="events-list-day"
          v-for="day in calendarEventsByDate"
          :key="day.dayId"
        >
          <div class="events-list-day-scroll-el" :id="day.dayId"></div>
          <div class="events-list-header">
            <span class="events-list-day-title h3">
              {{ day.dayTitle }}
            </span>
          </div>
          <div class="events-list-events">
            <NuxtLink
              class="undecorated-router-link"
              v-for="calendarEvent in day.dayEvents"
              :key="calendarEvent.id"
              :to="{
                name: 'studio-event-details',
                params: { event_id: calendarEvent.id },
              }"
              :target="eventClickTarget"
            >
              <CompactEvent :event="calendarEvent" />
            </NuxtLink>
          </div>
        </div>
      </div>
      <div class="show-more-container">
        <HMButton v-if="canShowMoreEvents" @click="showMoreEvents">
          {{ $t('GENERAL.LABEL.SHOW_MORE') }}
        </HMButton>
      </div>
      <div class="list-end-space" v-if="!!weeks.length"></div>
    </div>
    <div class="no-events" v-show="!weeks.length">No events scheduled.</div>
  </div>
</template>

<script>
import _ from 'lodash-es';
import CompactEvent from './CompactEvent';
import {
  generateAllCalendarEvents,
  generateCalendarWeeks,
  groupCalendarEventsByDay,
  groupCalendarEventsByWeek,
} from '@/utils/calendar';

function setCalendarPosition(activeDate) {
  const currentPosition = Math.max(this.offsetTop, window.scrollY);
  const onDayPosition = this.calendarEventsPosition.findIndex(
    position => currentPosition >= position
  );
  if (onDayPosition >= 0 && this.scrollListenerEnabled) {
    const event = this.calendarEventsByDate[
      this.calendarEventsByDate.length - onDayPosition - 1
    ];
    if (!event) return;
    this.activeDate = event.dayId;

    const newSwiperActiveIndex = this.weeks.findIndex(
      week => week.weekId === event.weekId
    );
    this.swiper.swiper.slideTo(newSwiperActiveIndex, 500);
  }
}

const dayHeight = 65;
const cardHeight = 81;
const topBarHeight = 60;

export default {
  name: 'FullEventsCalendar',
  components: {
    CompactEvent,
  },
  props: {
    calendarEvents: {
      type: Array,
      default() {
        return [];
      },
    },
    hasMoreEvents: {
      type: Boolean,
      default() {
        return false;
      },
    },
    openInNewWindow: {
      type: Boolean,
      default() {
        return false;
      },
    },
    template: {
      type: String,
      default() {
        return '';
      },
    },
    enableScrollToDay: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      swiperActiveIndex: 0,
      activeDate: null,
      scrollListenerEnabled: true,
      swiperOption: {
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 0,
        threshold: 10,
        navigation: {
          nextEl: '.swiper-button-next-custom',
          prevEl: '.swiper-button-prev-custom',
        },
      },
      offsetTop: 0,
      offsetTopInterval: null,
    };
  },
  computed: {
    eventClickTarget() {
      return this.openInNewWindow ? '_blank' : '';
    },
    templateType() {
      return this.template;
    },
    studioURL() {
      return this.$studioURL;
    },
    allCalendarEvents() {
      let events = [];
      events = generateAllCalendarEvents(this.calendarEvents);
      return events;
    },
    canShowMoreEvents() {
      return this.hasMoreEvents;
    },
    calendarEventsByDate() {
      return groupCalendarEventsByDay(this.allCalendarEvents);
    },
    findUpcomingDayWithEvents() {
      let upcomingDayWithEvents = null;
      this.weeks.find(week => {
        upcomingDayWithEvents = week.weekDays.find(
          day => day.isUpcoming && day.eventsInDay > 0
        );
        if (upcomingDayWithEvents) {
          return true;
        }
      });
      return upcomingDayWithEvents;
    },
    weeks() {
      const calendarEventsByWeek = groupCalendarEventsByWeek(
        this.allCalendarEvents
      );
      return generateCalendarWeeks(calendarEventsByWeek);
    },
    calendarEventsPosition() {
      return this.calendarEventsByDate
        .map(day => day.dayEvents.length)
        .slice(0, -1)
        .reduce(
          (positions, eventsNumber) => {
            positions.push(
              dayHeight +
                eventsNumber * cardHeight +
                positions[positions.length - 1]
            );
            return positions;
          },
          [0]
        )
        .map(p => p + this.offsetTop - topBarHeight)
        .reverse();
    },
    swiper() {
      return this.$refs.calendarSwiper;
    },
    calendarTitle() {
      return this.weeks.length
        ? this.weeks[this.swiperActiveIndex].month
        : null;
    },
  },
  methods: {
    action(event) {
      window.alert('Go to Event: ' + event.event_name);
    },
    showWeek(weekIndex) {
      return [weekIndex - 1, weekIndex, weekIndex + 1].includes(
        this.swiperActiveIndex
      );
    },
    selectDate(day) {
      if (!day) return;
      if (
        this.calendarEventsByDate.find(
          calendarEvent => calendarEvent.dayId === day.dayId
        )
      ) {
        const dayElement = document.getElementById(day.dayId);
        this.scrollListenerEnabled = false;
        dayElement.scrollIntoView({
          behavior: 'auto',
          block: 'start',
        });
        setTimeout(() => {
          this.scrollListenerEnabled = true;
        }, 500);
      }

      this.activeDate = day.dayId;
    },
    getOffsetTop() {
      return this.$refs['daysSwiper'].offsetTop;
    },
    showMoreEvents() {
      this.$emit('loadMore');
    },
    setCalendarPositionThrottle: _.throttle(setCalendarPosition),
    setCalendarPositionDebounce: _.debounce(setCalendarPosition, 500),
    onResize() {
      this.offsetTop = this.getOffsetTop();
      this.setCalendarPositionDebounce();
    },
    scrollToDay() {
      if (!this.calendarEvents?.length) return;

      this.$nextTick(() => {
        this.selectDate(this.findUpcomingDayWithEvents);
      });

      this.setCalendarPositionThrottle();
      clearInterval(this.offsetTopInterval);
      let offsetTopIntervalRep = 0;
      this.offsetTopInterval = setInterval(() => {
        if (offsetTopIntervalRep >= 20) clearInterval(this.offsetTopInterval);
        offsetTopIntervalRep++;
        const newOffsetTop = this.getOffsetTop();
        if (this.offsetTop !== newOffsetTop) {
          this.offsetTop = newOffsetTop;
          this.setCalendarPositionThrottle();
        }
      }, 500);
    },
  },
  watch: {
    calendarEvents() {
      if(process.client) {
        if (this.enableScrollToDay) this.scrollToDay();
      }
    },
  },
  mounted() {
    this.swiper.addEventListener('slideChange', () => {
      this.swiperActiveIndex = this.swiper.swiper.activeIndex;
    });

    window.addEventListener('scroll', this.setCalendarPositionThrottle);
    window.addEventListener('scroll', this.setCalendarPositionDebounce);
    window.addEventListener('resize', this.onResize);
    if (!this.$route.meta.preventScroll && this.enableScrollToDay) {
      this.scrollToDay();
    }
  },
  beforeUnmount() {
    clearInterval(this.offsetTopInterval);
    window.removeEventListener('scroll', this.setCalendarPositionThrottle);
    window.removeEventListener('scroll', this.setCalendarPositionDebounce);
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.show-only-mobile {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.hide-on-mobile {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.swiper {
  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after,
  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    font-size: 22px;
    font-weight: bold;
    display: none;
    @include media-breakpoint-up(md) {
      display: flex;
    }
  }
}

.full-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  background: white;
  width: 98vw;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0%);
}

.days-swiper {
  position: sticky;
  top: $shop-topbar-height;
  z-index: $zindex-fixed + 2;
  background: white;
  margin: 0 -15px;
  @include media-breakpoint-up(md) {
    margin: 0;
  }
}

.template-widget {
  position: sticky;
  top: 0;
  z-index: $zindex-fixed + 2;
  background: white;
  margin: 0 -15px;
  @include media-breakpoint-up(md) {
    margin: 0;
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.header-title {
  font-size: 20px;
  color: white;
}

.week {
  display: flex;
  justify-content: space-around;
  @include media-breakpoint-up(md) {
    margin: 0 50px;
  }
}

.day {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  height: 80px;
  width: 80px;
  max-width: 80px;
  margin: 10px 5px;
  flex: 1;

  @include media-breakpoint-up(md) {
    margin: 10px 15px;
  }

  &--active {
    .day-day {
      color: white;
      background-color: var(--brand-color);
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &--enabled {
    cursor: pointer;

    &:hover {
      .day-day {
        color: white;
        background-color: rgba(#000, 0.05);
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &--disabled {
    opacity: 0.5;
  }
}

.day-week-day {
  font-size: 10px;
  margin-bottom: 5px;
  font-weight: 500;
}

.day-day {
  font-size: 18px;
  font-weight: 500;
}

.events-list-day-scroll-el {
  transform: translateY(-210px);
}

.events-list-header {
  display: flex;
  align-items: flex-end;
  height: 65px;
  border-bottom: 1px solid #d6d4d7;
  padding-bottom: 10px;
}

.show-more-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.no-events {
}

.list-end-space {
  // 85px - 50px - 205px - 60px
  padding-top: calc(100vh - 440px);
}

.calendar-navigation-btn {
  border-radius: 6px;
  border: 1px solid $medium-gray;
  padding: 6px 14px;
  background-color: white;
  position: absolute;
  top: 50%;
  height: 42px;
  margin-top: calc(-42px / 2);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  &.prev {
    left: 1px;
    right: auto;
  }

  &.next {
    right: 1px;
    left: auto;
  }

  &:hover {
    color: var(--brand-color, $coral);
    border-color: var(--brand-color, $coral);
    box-shadow: 0px 0px 1px 1px var(--brand-color, $coral);
    background-color: $white;
  }
}
</style>
