<template>
  <div class="add-to-homescreen-banner" v-if="showAddToHomeScreen">
    <i class="fas fa-times close" @click="close"></i>
    <AddToHomeScreen
      @finish="close"
      :studioName="studio.studio_name"
      :appIcon="appIcon"
    />
  </div>
</template>

<script>
import AddToHomeScreen from '@/components/pwa/AddToHomeScreen';
import { mapState } from 'pinia';
import { useStudioStore, useGlobalsStore } from '@/stores';

export default {
  name: 'AddToHomeScreenBanner',
  components: {
    AddToHomeScreen,
  },
  setup() {
    const studioStore = useStudioStore();
    const globalsStore = useGlobalsStore();

    return {
      studioStore,
      globalsStore,
    };
  },
  methods: {
    close() {
      this.globalsStore.setShowInstallPWA(false);
    },
  },
  computed: {
    ...mapState(useStudioStore, {
      studio: 'currentStudio',
    }),
    ...mapState(useGlobalsStore, {
      isPWA: 'isPWA',
      showCookieNotice: 'showCookieNotice',
      showInstallPWA: 'showInstallPWA',
    }),
    showAddToHomeScreen() {
      return (
        this.studio && // studio is loaded
        !this.isPWA && // instance is not PWA app
        !this.showCookieNotice && // first user has to confirm cookie notice
        this.showInstallPWA // notice was not dismiss yet
      );
    },
    appIcon() {
      return this.studio?.app_icon?.thumbnail;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.add-to-homescreen-banner {
  z-index: 10000;
  border-top: 1px solid $medium-gray;
  width: 100%;
  box-sizing: border-box;
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1rem;
}

.close {
  padding: 1rem;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}
</style>
